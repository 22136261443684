@import url('https: //fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700');

$primary:#FF6060;
$grey: #F6F6F6;

* {
    margin:0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: $primary;
    font-size: 18px;
}
main, header {
    max-width:1240px;
    margin:0 auto;
    padding: 20px;
}

//Header

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.logo {
    height: 60px;
    width: auto;
}

nav {
    display: flex;
    gap: 60px;

    a {
        color: $primary;
        font-size: 24px;
        text-decoration: none;
        transition: all 500ms ease-in-out;

        &:hover,
        &.active {
            text-decoration: underline;
        }
    }
}

// Home
.banner {
    border-radius: 25px;
    overflow: hidden;
    height: 223px;
    line-height: 223px;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    h2 {
        font-weight: 500;
        font-size: 48px;
        color: white;
        background: rgba($color: #000000, $alpha: 0.3);
        z-index: 2;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
    }
    img {
        object-fit: cover;
        mix-blend-mode: darken;
        height: 223px;
        width: 100%;
        
    }
}
.cardList {
    background-color: $grey;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    gap:60px;
    article, .cardContent {
        width: 340px;
        height: 340px;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        position: relative;
        img {
            object-fit: cover;
            width: 340px;
            height: 340px;
            transition: all 300ms ease-in-out;
        }
        h3 {
            z-index: 2;
            position: absolute;
            bottom: 0;
            width: calc(100% - 180px);
            color: white;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
            padding: 20px 160px 20px 20px;
            transition: all 500ms ease-in-out;
            font-size: 18px;
        }
        &:hover {
            h3{
                padding: 160px 160px 160px 20px; 
            }
            img {
                scale: 1.5;
            }
        }
    }
}

//Logement Page

.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;

    height: 415px;
    overflow: hidden;
    border-radius: 25px;
    margin-bottom: 30px;
    .image {
            width: 1240px;
            max-width: 100%;
            height: 415px;
            object-fit: cover;
        }
        .right-arrow, .left-arrow {
            position: absolute;
            right: 32px;
            user-select: none;
            cursor: pointer;
            height: 79px;
            z-index: 10;
        }
        .left-arrow {
            left: 32px;
            right: none;
        }
        .position {
            z-index: 10;
            position: absolute;
            bottom: 30px;
            color: white;
        }
        .slide {
            opacity: 0;
            transition-duration: 1s ease;
        }
    
        .slide.active {
            opacity: 1;
            transition-duration: 1s;
            transform: scale(1.08);
        }
}
.headerLogement {
    display: flex;
    justify-content: space-between;
    h1 {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .tag {
        list-style: none;
        display: flex;
        gap: 10px;
        margin: 20px 0;
        li {
            background-color: $primary;
            color: white;
            font-size: 14px;
            border-radius: 20px;
            height: 25px;
            line-height: 25px;
            width: 115px;
            text-align: center;
        }
    }
    .host {
        display: flex;
        gap: 20px;
        align-items: center;
        max-width: 190px;
        text-align: right;
        img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 100%;
        }
    }
    .rating {
        list-style: none;
        display: flex;
        gap:10px;
        padding-top: 30px;
        li {
            width: 30px;
        }
    }
}
.logementDetails {
    display: flex;
    gap: 75px;
    .collapse {
        width: 50%;
    }
}
.collapse {
    position:relative;
    overflow: hidden;
    margin-bottom: 40px;
    ul {
        list-style: none;
    }
    button {
        color:white;
        background-color: $primary;
        position: relative;
        width: 100%;
        padding: 0 10px;
        height: 52px;
        line-height: 52px;
        border-radius: 10px;
        border:0;
        text-align: left;
        cursor: pointer;
        z-index: 2;
        &::after{
            content: "";
            display: block;
            width: 26px;
            height:52px;
            position: absolute;
            top:0;
            right: 20px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .text {
        transition: all 500ms ease-in-out;
        overflow: hidden;
        padding: 30px 20px;
        margin-top: -10px;
        position: relative;
        background-color: $grey;
        border-radius: 0 0 10px 10px;
    }
    &.open {
        .text{
            visibility: visible;
            top:0;
        }
        button::after {
            background-image: url('../assets/arrow_up.svg');
        }
    }
        &.close {
            .text {
                visibility: hidden;
                top:-500px;
                height:0px; 
                padding: 0px;
                margin-top: 0;
            }
    
            button::after {
                background-image: url('../assets/arrow_down.svg');
            }
        }
}

// About 
.banner.about {
    background-image: url('../assets/kalen-emsley-Bkci_8qcdvQ-unsplash\ 2.jpg');
}
.collapseAbout {
    max-width:1024px;
    margin:0 auto;
}

// Footer 

footer {
    background-color: black;
    display: flex;
    flex-direction: column;
    gap:30px;
    padding:60px;
    font-size: 24px;
    align-items: center;
    img {
        width:auto;
        height: 35px;
    }
    p {
        color: white;
    }
}

// About 
.banner .about {
    background-image: url('../assets/kalen-emsley-Bkci_8qcdvQ-unsplash\ 2.jpg');
}

// Error 
.error404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 50vh;
    padding: 50px 0;
    text-align: center;
    h1 {
        font-size: 288px;
        font-weight: 700;
    }

}

@media (max-width:768px) {
    * , nav a{
        font-size: 12px;
    }
    nav {
        gap:10px;
    }
    nav a {
        text-transform: uppercase;
    }
    .logo {
        height:37px;
    }
    .cardList {
        flex-direction: column;
        padding: 0;
        background-color: white;
        align-items: center;
    }
    .banner {
        height: 110px;
        line-height: normal;
    }
    .banner h2 {
        font-size: 24px;
        padding: 26px 80px 26px 30px;
        text-align: left;
        height: 58px;
        width: calc(100% - 110px);
    }
    .headerLogement, .logementDetails {
        flex-direction: column;
        gap:0;
    }
    .headerLogement h1 {
        font-size: 18px;
    }
    .logementDetails .collapse {
        width: inherit;
    }
    .headerLogement .right {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 30px;
        justify-content: space-between;
    }
    .headerLogement .rating li {
        width: 15px;
    }
    .headerLogement .host {
        max-width: 140px;
    }
    .slider .right-arrow, .slider .left-arrow {
        height:20px;
    }
    .slider, .slider.image {
        height:255px;
    }
    header {
        padding-bottom: 0;
    }
    .banner.about h2, .banner.about {
        height: 223px;
    }
    .error404 {
        justify-content: space-around;
        h1 {
            font-size: 96px;
        }
        p {
            font-size: 18px;
        }
        a {
            font-size: 14px;
        }
    }
}